/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../../_metronic/layout/core'
// import Path from 'path';
import axios from 'axios';
import TimeSlider from "@arcgis/core/widgets/TimeSlider";

import "@arcgis/core/assets/esri/themes/light/main.css";
// import "https://js.arcgis.com/4.19/@arcgis/core/assets/esri/themes/light/main.css";


const API_URL = process.env.REACT_APP_API_URL
const token: string | null = localStorage.getItem('access_token')
axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }


const PlaygroundPage: React.FC = () => {
  console.log(`Init PlaygroundPage`); // output: "This stop interval is every 3 weeks."

  // See: https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-TimeSlider.html#mode
  var timeSlider: TimeSlider;
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    timeSlider = new TimeSlider({
      container: "timeSliderDiv",
      fullTimeExtent: {
        start: new Date(2019, 5, 1),
        end: new Date(2020, 0, 1)
      },
      disabled: false,
      actions:
        [{
          id: "quake",
          icon: "exclamation-mark-triangle",
          title: "Jump to Earthquake"
        }]
    });

    console.log("timeSlider:  " , timeSlider)

    timeSlider.watch("timeExtent", (timeExtent) => {
      console.log("Time extent now starts at", timeExtent.start, "and finishes at:", timeExtent.end);
    });

  }); 







  return (
    <>
      <div>Welcome to the playground</div>
      <div id="timeSliderDiv"></div>
    </>
  )
}

export { PlaygroundPage }


