import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {WaterstandenPage} from './WaterstandenPage'

const WaterstandenPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Waterstanden</PageTitle>
      <WaterstandenPage />
    </>
  )
}

export default WaterstandenPageWrapper
