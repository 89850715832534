/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";

import { KTSVG } from '../../../_metronic/helpers'
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../../_metronic/layout/core'

import './WaterstandenPage.css'

const API_URL = process.env.REACT_APP_API_URL
const token: string | null = localStorage.getItem('access_token') 
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

interface waterstandEntry {
  // export interface dbEntry {
  location: string,
  measurement: number | null,
  time: string | null,
}

const WaterstandenPage: React.FC = () => {

  const [init, setInit] = useState(true);
  const [type, setType] = useState("mtb");
  const [filterdfilelist, setFilteredFileList] = useState<waterstandEntry[]>();



  console.log("Render Started. Init = " + init)

  let naam: string
  let meting: number
  let tijd: string
  let data: waterstandEntry[] = []



  async function load_db_entries( ) {
    console.log('Reloading potreefolders')
    await axios.get(`${API_URL}/getWaterstanden`).then((res) => {
      console.log(res)
      if (res.data.Succesvol){
        console.log('success')
        
        for (let i = 0; i < res.data.WaarnemingenLijst.length; i++){
          naam = res.data.WaarnemingenLijst[i].Locatie.Naam;
          meting = res.data.WaarnemingenLijst[i].MetingenLijst[0].Meetwaarde.Waarde_Numeriek;
          tijd = res.data.WaarnemingenLijst[i].MetingenLijst[0].Tijdstip;
          
          let tijd_date = new Date(tijd) 

          
          var newEntry: waterstandEntry = {'location': naam , 'measurement': meting, 'time': tijd_date.toLocaleString()}
          
          data[i] = newEntry

        }

        // Sort alphabetically by the location property.
        data.sort((a, b) => a.location.localeCompare(b.location));

        setFilteredFileList(data)
      }
    })
  }



  // This code should run only once, to initialize the states that need initialization.
  if( init ) 
  {
    load_db_entries( )
    setInit(false)
    
  }



  return (
    <>
      <div className="container d-flex flex-column flex-lg-row" >
      <br></br>
        <div className="card card-docs mb-2 card mb-2 card-body fs-6 py-15 px-10 py-lg-15 px-lg-15" style = {{marginTop: "20px"}}>
          

      <br></br>
          {/* <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Member Statistics</span>
              <span className="text-muted mt-1 fw-semibold fs-7">Over 500 new members</span>
            </h3>
          </div>           */}

          <div className="table-responsive"  >
            <table className="table table-hover table-rounded table-striped border gy-1 gs-2">
              <thead>
                <tr className="fw-semibold fs-6 text-gray-800 border-bottom-2 border-gray-200" >
                  <th>Locatie</th>
                  <th>Waterhoogte [cm]</th>
                  <th>Tijdstip</th>
                </tr>
              </thead>

              <tbody >
                {filterdfilelist?.map((item, i) => (
                  <tr className="even" key={i}>
                    <td>{item['location']}</td>
                    <td>{item['measurement']}</td>
                    <td>{item['time']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </>
  )


}

export { WaterstandenPage }

