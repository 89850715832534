import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {FileUploadPage} from './FileUploadPage'

const FileUploadPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>File Upload</PageTitle>
      <FileUploadPage />
    </>
  )
}

export default FileUploadPageWrapper
