import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PotreePage} from './PotreePage'

const PotreePageWrapper: FC = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>Scan Viewer</PageTitle> */}
      <PotreePage />
    </>
  )
}

export default PotreePageWrapper
