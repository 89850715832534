import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {OverviewPage} from './OverviewPage'

const OverviewPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Overview</PageTitle>
      <OverviewPage />
    </>
  )
}

export default OverviewPageWrapper
