import React from 'react';
import styled from "styled-components/macro";
// import * as THREE from "../../../build/potree/libs/three.js/build/three.module.js";
import * as THREE from 'three';

import "./potree.css";
import "./libs/jquery-ui/jquery-ui.min.css";
import "./libs/spectrum/spectrum.css";
import "./libs/CesiumWidget/CesiumWidget.css";
import "./libs/openlayers3/ol.css";
import "./libs/jstree/themes/mixed/style.css";
// import './libs/jstree/jstree.js'



import {getSasToken} from "../../pages/file-upload/azure-storage-blob" 
import axios from "axios"

import TimeSlider from "@arcgis/core/widgets/TimeSlider";
import "@arcgis/core/assets/esri/themes/light/main.css";
import { date } from 'yup';
// import jstree from 'jstree';


const Wrapper = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  height: 675px; 
  position: relative;
`;

// import vanillaJS Potree libs, /!\ would be best with proper ES6 import
const Potree = window.Potree
const proj4 = window.proj4
const Cesium = window.Cesium
const cesiumViewer = window.cesiumViewer
const d3 = window.d3
const toMap = window.toMap
const jstree = window.jstree

// Obtain a SAS token from the backend
const DATA_URL  = "https://struktonstorage.file.core.windows.net/strukton-data/"
const API_URL   = process.env.REACT_APP_API_URL

var viewerPotree = []
var viewerCesium = []

let timeSlider;

let show_covadem_data = false;
 
console.log("window: ");
console.log(window);

// Turn to true to allow debugging in React using Chrome's dev tools (Bronnen)
if(false) {
  debugger;
}
export default class PointcloudNavigator extends React.Component {
    constructor(props) {
        super(props)

        this.potreeContainerDiv = React.createRef();
        // this.viewerCesium = []
        // this.viewerPotree = []
        this.SAS_TOKEN = "Empty";
        this.data_baggervlakken = [];

    }

    state = {
        potree_file_list : [],
    }

    // Obtain the potreeFolders list and save it to the state of this class.
    // async load_db_entries() {
    //     console.log('Reloading potreefolders');
    //     axios.get(`${API_URL}/PotreeFolders`).then((res) => {
    //         this.setState( {potree_file_list: res.data } )
    //         return res.data
    //     })}

        // async load_db_entries() {
        //     console.log('Reloading potreefolders');

        //     return res.data
        // }        
   
    async load_init(){
        console.log('function: load_init')
        Potree.loadPointCloud( DATA_URL + 'test_grid/potree/metadata.json' + this.SAS_TOKEN, 'test_grid', async e => {
            let scene = viewerPotree.scene;
            
            scene.addPointCloud(e.pointcloud);

            let material             = e.pointcloud.material;
            material.pointSizeType   = Potree.PointSizeType.ADAPTIVE;
            material.size            = 0.1;
            material.activeAttributeName = "composite";
            material.activeAttributeName = "intensity"; 
            material.intensityRange = [0,65355];
            material.weightRGB       = 0;
            material.weightElevation = 0;
            material.weightIntensity = 1;
            material.gradient =  Potree.Gradients.STRUKTON;

            scene.view.position.set(202579.98,512312.20, 100000);
            scene.view.lookAt(202579.98,512312.20, 0);
            scene.view.yaw = 0;


            proj4.defs("EPSG:28992","+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs");

            let pointcloudProjection = proj4.defs("EPSG:28992");
            let mapProjection        = proj4.defs("WGS84");

            window.toMap   = proj4(pointcloudProjection, mapProjection);
            window.toScene = proj4(mapProjection, pointcloudProjection);
        });
    }        
 
    async load_baggervlakken(db_potree_folders){
      console.log("load_baggervlakken from azure")      
      this.data_baggervlakken = db_potree_folders.filter(db_potree_entry => (db_potree_entry?.type?.includes('bv')  && db_potree_entry?.progress === 100 )   ); // Filter the raw sql data on type 'bv' 
      console.log( "Baggervlakken: ", this.data_baggervlakken)

      // add shapefile
      // let pointcloudProjection = proj4.defs("EPSG:28992");
      // let mapProjection        = proj4.defs("WGS84");
      // let transform = proj4(pointcloudProjection, pointcloudProjection);

      //const loader = new Potree.ShapefileLoader();
      // loader.transform = transform;

      // group all shapefile scene nodes into this node
      const shapeNode = new THREE.Object3D();
      viewerPotree.scene.scene.add(shapeNode);

      let BV_names = []
      let BV_shapes = []
      // // read .json file for configuration of all the shapefile
      // // this must be in a for-loop
      // for (let i = 0; i < this.data_baggervlakken.length ; i++) {
          
      // // 	// BV_names[i] = shapefiles_path[i].split('/')[1]
          
      // //   console.log("baggervlakken load url: ", DATA_URL + this.data_baggervlakken[i].potree_path + ".shp" + this.SAS_TOKEN)
      // 	BV_shapes[i] = await loader.load(DATA_URL + this.data_baggervlakken[i].potree_path + this.SAS_TOKEN);
      // //   // BV_shapes[i].setResolution(100, 100);

      //   const size = viewerPotree.renderer.getSize(new THREE.Vector2());
      //   BV_shapes[i].setResolution(size.width*3, size.height*3); // What does this do? Setting it to a small value affects performance a lot

      //   // BV_shapes[i].node.material.color.setRGB(0, 0, 0)
      // 	shapeNode.add(BV_shapes[i].node);

      // 	BV_shapes[i].node.traverse(node => {
      // 		if(node.material){
      // 			node.material.color.setRGB(0, 0, 0)
      // 		}
      // 	});
      // }

      // // this is necessary so that fat lines are correctly sized
      // viewerPotree.addEventListener("update", () => {                  
      // 	const size = viewerPotree.renderer.getSize(new THREE.Vector2());
      // 	for (let i = 0; i < this.data_baggervlakken.length ; i++) {
      // 		BV_shapes[i].setResolution(size.width*5, size.height*5);
      // 	}
      // 	console.log('size: ', size)
      // });

      // viewerPotree.onGUILoaded(() => {
      // 	// Add entry to object list in sidebar
      //   console.log("onGUILoaded")
      // 	let tree = document.getElementById("jstree_scene")
      //   console.log(tree)
      // 	let parentNode = "other";
      // 	for (let i = 0; i < this.data_baggervlakken.length; i++) {
      // 		let BV_shapesID = jstree(tree, 'create_node', parentNode, { 
      // 				"text"  : this.data_baggervlakken[i].zip_filename, 
      // 				"icon"  : `${Potree.resourcePath}/icons/triangle.svg`,
      // 				"object": BV_shapes[i].node,
      // 				"data"  : BV_shapes[i].node,
      // 			}, 
      // 			"last", false, false);
      // 		tree.jstree(BV_shapes[i].node.visible ? "check_node" : "uncheck_node", BV_shapesID);
      // 	}
      // });      
    }


    // wrap load code into an async function so that we can use "await"
    async load_pointclouds(db_potree_folders) {
        let pointclouds = []
        console.log("All db_potree_folders as extracted from the SQL database: " , db_potree_folders)

        if (db_potree_folders.length > 0) {
            for (let i = 0; i < db_potree_folders.length; i++) {
                // loop over the input to generate pointcloud units and add them to pointclouds
                // console.log("db_potree_folders[i].potree_path: " ,db_potree_folders[i])
                var skip_file = false;
                // Only select OS and MTB scans
                // If not (mtb or os)
                if (  !(db_potree_folders[i].type?.includes('mtb' ) || db_potree_folders[i].type?.includes('os' ) || db_potree_folders[i].type?.includes('cvd' ))  ){
                    continue
                }

                if ( db_potree_folders[i].potree_path == null || db_potree_folders[i].potree_path.length < 1 ) {
                    skip_file = true
                }     
                
                if ( db_potree_folders[i].is_uploaded == false ) {
                    skip_file = true
                }           

                if ( db_potree_folders[i].progress < 100 ) {
                    skip_file = true
                }                     
                
                if (skip_file){
                    // console.log('Skipping file: ', db_potree_folders[i] )
                    continue
                }
 
                try {
                //console.log("Adding file: ", db_potree_folders[i] )

                let my_name_array = db_potree_folders[i].potree_path.split('\\')
                let pointcloud_name = my_name_array[0]

                db_potree_folders[i].potree_path = db_potree_folders[i].potree_path.replace('\\', '/')
 
                // specify point clouds that are to be loaded and callbacks to invoke 
                let pointcloud_single = {}
                pointcloud_single = {
                    url: DATA_URL + db_potree_folders[i].potree_path + '/metadata.json' + this.SAS_TOKEN,
                    callback: (pointcloud) => {
                        pointcloud.name = db_potree_folders[i].zip_filename;
                        pointcloud.date = new Date(db_potree_folders[i].date_measurement);  
                        pointcloud.type = db_potree_folders[i].type;  
                        //pointcloud.visible = false;
                        let material = pointcloud.material;
                        material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
                        material.size = 0.1;
                        material.weightRGB = 0;

                        material.weightElevation = 0;
                        material.weightIntensity = 1;
                        material.intensityRange = [0, 65355];
                        material.activeAttributeName = "intensity";
                        material.gradient = Potree.Gradients.STRUKTON;

                        // Overwrite some props if the type is 'oeverscan'
                        if (db_potree_folders[i].type.includes('os')) {
                            material.activeAttributeName = "composite";
                            material.weightRGB = 0;
                            material.weightElevation = 1;
                            material.weightIntensity = 0;
                            material.elevationRange = [0, 25];
                            material.gradient = Potree.Gradients.SPECTRAL;
                        }

                        // Overwrite some props if the type is 'oeverscan'
                        if (db_potree_folders[i].type.includes('cvd')) {
                          material.activeAttributeName = "composite";
                          material.weightRGB = 0;
                          material.weightElevation = 1;
                          material.weightIntensity = 0;
                          material.elevationRange = [-8, -2];
                          material.gradient = Potree.Gradients.SPECTRAL;
                        }                        

                        proj4.defs("EPSG:28992", "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs");

                        let pointcloudProjection = proj4.defs("EPSG:28992");
                        let mapProjection = proj4.defs("WGS84");
 

                        window.toMap = proj4(pointcloudProjection, mapProjection);
                        window.toScene = proj4(mapProjection, pointcloudProjection);

                        // {
                        //     let bb = this.viewerPotree.getBoundingBox();

                        //     let minWGS84 = proj4(pointcloudProjection, mapProjection, bb.min.toArray());
                        //     let maxWGS84 = proj4(pointcloudProjection, mapProjection, bb.max.toArray());
                        // }

                    },
                }
                pointclouds.push(pointcloud_single)
 
            }
            catch (error)
            {
                console.error(error);
            } 


            }//end-for
        }//end-if

        // start loading all point clouds asynchronously, get a promise for each one that resolves when it's loaded
        let promises = pointclouds.map(p => Potree.loadPointCloud(p.url));

        var startDate = new Date( new Date().setMonth(new Date().getMonth() - 6) );
        var stopDate = new Date();

        // now iterate over all promises in order
        console.log("promises.length:",  promises.length)
        for (let i = 0; i < promises.length; i++) {
          // wait until this point cloud is loaded before processing the next one
          let pointcloud = (await promises[i]).pointcloud;
            
          pointclouds[i].callback(pointcloud);
          viewerPotree.scene.addPointCloud(pointcloud);
          
          var javaDate = viewerPotree.scene.pointclouds[i].date;
          
          if (startDate < javaDate &&  stopDate  > javaDate ) {
              // console.log("SHOW")
            if( show_covadem_data & viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
              viewerPotree.scene.pointclouds[i].visible = true;
            } 
    
            if( !show_covadem_data & viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
              viewerPotree.scene.pointclouds[i].visible = false;
            }    
    
            if( show_covadem_data & !viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
              viewerPotree.scene.pointclouds[i].visible = false;
            }     
            
            if( !show_covadem_data & !viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
              viewerPotree.scene.pointclouds[i].visible = true;
            }                            
          
          } else {                
            viewerPotree.scene.pointclouds[i].visible = false;
          }
        }

    } // end-load_pointclouds
 
    

    onCheckboxColormapClick(event){
      for (let i = 0; i < viewerPotree.scene.pointclouds.length; i++)
      {
        if( viewerPotree.scene.pointclouds[i].type.includes('os') )
        {
          continue
        }
        if( viewerPotree.scene.pointclouds[i].type.includes('cvd') )
        {
          continue
        }        
        if( event.target.checked )
        {
          // if( viewerPotree.scene.pointclouds[i].type.contains('mtb') ){
            viewerPotree.scene.pointclouds[i].material.activeAttributeName = "intensity";
            viewerPotree.scene.pointclouds[i].material.weightElevation = 0;
            viewerPotree.scene.pointclouds[i].material.weightIntensity = 1;            
            viewerPotree.scene.pointclouds[i].material.gradient = Potree.Gradients.STRUKTON
          // }
        }
        else{
          viewerPotree.scene.pointclouds[i].material.activeAttributeName = "composite";
          viewerPotree.scene.pointclouds[i].material.weightElevation = 1;
          viewerPotree.scene.pointclouds[i].material.weightIntensity = 0;                  
          viewerPotree.scene.pointclouds[i].material.gradient = Potree.Gradients.SPECTRAL
          viewerPotree.scene.pointclouds[i].material.elevationRange = [-2, 2];
        }
      }      
    }    

    onCheckboxShowCovademData(event){
      for (let i = 0; i < viewerPotree.scene.pointclouds.length; i++)
      {
          if( event.target.checked )
          {
            show_covadem_data = true;
            if( viewerPotree.scene.pointclouds[i].type.includes('cvd') ) {
              viewerPotree.scene.pointclouds[i].visible = true;
            } else
            {
              viewerPotree.scene.pointclouds[i].visible = false;
            }
          }
          else{
            show_covadem_data = false;
            if( viewerPotree.scene.pointclouds[i].type.includes('cvd') ) {
              viewerPotree.scene.pointclouds[i].visible = false;
            } else
            {
              viewerPotree.scene.pointclouds[i].visible = true;
            }          
          }        

      }      
    }        

    onCheckboxHideOutsideBRVClick(event){
      console.log('clicky')
      if( event.target.checked ) {
        viewerPotree.setfilterShapeIDRange(0,1000000)
      }
      else {
        viewerPotree.setfilterShapeIDRange(-1,1000000)
      }
    }        

    
    
    
    render()    


    
    {
        return (
          <div id='potree-root'>
            {/* <Wrapper ref={this.potreeContainerDiv} className={"potree_container "} style = {{position: "absolute", width: "100%", height: "100%", left: "0px", top: "0px", padding: "0px"}}> */}
            <div ref={this.potreeContainerDiv} className='potree_container' style={{position: 'absolute', width: "calc(100vw - 265px)", height: '100%', top: "0px", paddingLeft: "inherit", paddingRight: "inherit",  zIndex:'400'}}>
              <div id='potree_render_area'>
                <div id='cesiumContainer' style={{ position: 'absolute', width: "100%", height: '100%', backgroundColor: 'orange', paddingLeft: "inherit", paddingRight: "inherit" }}></div>
                <div id="slider"  style={{position: 'absolute', width: "100%", bottom:'0px', left:"0px", right:'0px',  zIndex:'500'}}></div>
              


                {/* Start of the legend */}
                <div id="legend"  style={{position: 'absolute', width: "250px", height: "400px", top:'20px' , right:'20px',  zIndex:'500'}}>
                  <div className='accordion' id='kt_accordion_1'>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                        <button
                          className='accordion-button fs-4 fw-bold collapsed '
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#kt_accordion_1_body_1'
                          aria-expanded='false'
                          aria-controls='kt_accordion_1_body_1'
                        >
                          Legend
                        </button>
                      </h2>
                      <div
                        id='kt_accordion_1_body_1'
                        className='accordion-collapse collapse.show'
                        aria-labelledby='kt_accordion_1_header_1'
                        data-bs-parent='#kt_accordion_1'
                      >
                        <div className='accordion-body'>
                        <p> Afstand tot het baggerreferentievlak</p>
                          {/* <div className = "p-10 card shadow p-3 mb-5  rounded" style = {{backgroundColor: "rgba(255,255,255,0.8)"}}> */}
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(112,50,50,1)"}}></div>    <p className = "col-9" style = {{margin: "0px"}}>  + 2m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(240,65,40,1)"}}></div>    <p className = "col-9" style = {{margin: "0px"}}>  1 tot 2 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(224,154,17,1)"}}></div>   <p className = "col-9" style = {{margin: "0px"}}>  0.5 tot 1 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(250,213,129,1)"}}></div>  <p className = "col-9" style = {{margin: "0px"}}>  0.2 tot 0.5 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(249,255,27,1)"}}></div>   <p className = "col-9" style = {{margin: "0px"}}>  0.1 tot 0.2 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(252,255,191,1)"}}></div>  <p className = "col-9" style = {{margin: "0px"}}>  0 tot 0.1 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(163,255,177,1)"}}></div>  <p className = "col-9" style = {{margin: "0px"}}>  -0.1 tot 0 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(58,169,9,1)"}}></div>     <p className = "col-9" style = {{margin: "0px"}}>  -0.2 tot -0.1 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(58,169,9,1)"}}></div>     <p className = "col-9" style = {{margin: "0px"}}>  -0.5 tot -0.2 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(67,195,254,1)"}}></div>   <p className = "col-9" style = {{margin: "0px"}}>  -1 tot -0.5 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(64,106,254,1)"}}></div>   <p className = "col-9" style = {{margin: "0px"}}>  -2 tot -1 m </p></div> 
                            <div className = "row"><div className = "col-3" style = {{height: "20px", backgroundColor: "rgba(39,73,167,1)"}}></div>    <p className = "col-9" style = {{margin: "0px"}}>   -2 m </p></div> 
                            <br></br>
                            <div className="form-check form-check-custom form-check-solid">                    
                            <input className="form-check-input" type="checkbox" value="" defaultChecked = {true} onChange={this.onCheckboxColormapClick} id="flexCheckDefault"/>
                              <label className="form-check-label" for="flexCheckDefault" >
                                  Colormap w.r.t. BRV<br></br><cite> </cite>
                              </label>    
                            </div>   
                            <br></br>
                            <div className="form-check form-check-custom form-check-solid">                    
                            <input className="form-check-input" type="checkbox" value="" defaultChecked = {false} onChange={this.onCheckboxHideOutsideBRVClick} id="flexCheckDefault"/>
                              <label className="form-check-label" for="flexCheckDefault" >
                                  Hide data outside BRV <br></br><cite></cite>
                              </label>    
                            </div> 
                            <br></br>
                            <div className="form-check form-check-custom form-check-solid">                    
                            <input className="form-check-input" type="checkbox" value="" defaultChecked = {false} onChange={this.onCheckboxShowCovademData} id="flexCheckDefault"/>
                              <label className="form-check-label" for="flexCheckDefault" >
                                  Show Covadem Data <br></br><cite></cite>
                              </label>    
                            </div>                                                           
                          {/* </div>  */}
                        </div>
                      </div>
                    </div>
                  </div>{/* End of accordion */}
                </div>    
                {/* End of legend  */}
                {/* <div id="slider"  style={{position: 'absolute', width: "calc(100vw - 265px)", bottom:'65px', left:"0px", right:'0px',  zIndex:'100'}}></div> */}
              </div>
              <div id="potree_sidebar_container" > </div>
              {/* </Wrapper> */}
            </div>
            {/* <div id="slider"  style={{position: 'absolute', width: '75%', height: '25px', right: '25px', bottom:'63px', padding: '0px', zIndex:'100'}}></div> */}
            
          </div>
        )
    }

    async componentDidMount() {

      this.SAS_TOKEN = '?' + (await getSasToken())

      viewerCesium = new Cesium.Viewer('cesiumContainer', {
        useDefaultRenderLoop: false,
        animation: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: true,
        navigationHelpButton: false,
        imageryProvider: Cesium.createOpenStreetMapImageryProvider({
          url: 'https://a.tile.openstreetmap.org/',
          maximumLevel: 19,
        }),
        terrainShadows: Cesium.ShadowMode.DISABLED,
      })

      viewerPotree = new Potree.Viewer(document.getElementById('potree_render_area'), {
        useDefaultRenderLoop: false,
      })

      console.log('this.state.potree_file_list')
      console.log(this.state.potree_file_list)

      var res = await axios.get(`${API_URL}/PotreeFolders`)
      var db_potree_folders = res.data


      let cp = new Cesium.Cartesian3(190000, 475000, 0)
      viewerCesium.camera.setView({
        destination: cp,
        orientation: {
          heading: 0, //50,
          pitch: 0, //-Cesium.Math.PI_OVER_TWO * 0.5,
          roll: 0.0,
        },
      })

      // IMPORTANT: The potree viewer must be created after the cesium one (z-indexing)
      viewerPotree.setEDLEnabled(true)
      viewerPotree.setFOV(60)
      viewerPotree.setPointBudget(3_000_000)
      viewerPotree.setMinNodeSize(50)
      viewerPotree.loadSettingsFromURL()
      viewerPotree.setBackground(null)
      // viewerPotree.useHQ = true
      viewerPotree.setDescription(``)
      viewerPotree.setControls(viewerPotree.earthControls)

      viewerPotree.loadGUI(() => {
        viewerPotree.setLanguage('en')
      })

      // this.load_init()
      let scene = viewerPotree.scene;

      scene.view.position.set(190000, 475000, 100000);
      scene.view.lookAt(190000, 475000, 0);
      scene.view.yaw = 0;

      // console.log("for (let i = 0; i < viewerPotree.scene.pointclouds.length; i++)")

      // for (let i = 0; i < viewerPotree.scene.pointclouds.length; i++)
      // {
      //   console.log("viewerPotree.scene.pointclouds[i].material", viewerPotree.scene.pointclouds[i].material)
      // }



      this.load_pointclouds(db_potree_folders)
      this.load_baggervlakken(db_potree_folders)

      // ---------------------------------------------------------------------------------------------------------- //
      // Start of the Slider Code                                                                                   //
      // ---------------------------------------------------------------------------------------------------------- //
      // See: https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-TimeSlider.html#mode       //
      // ---------------------------------------------------------------------------------------------------------- //
      

    function set_visibility_pointclouds(startDate, stopDate) {
      for (let i = 0; i < viewerPotree.scene.pointclouds.length; i++)
      {
        var javaDate = viewerPotree.scene.pointclouds[i].date;
        if (startDate < javaDate &&  stopDate  > javaDate ) {
              // console.log("SHOW")
          if( show_covadem_data & viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
            viewerPotree.scene.pointclouds[i].visible = true;
          } 

          if( !show_covadem_data & viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
            viewerPotree.scene.pointclouds[i].visible = false;
          }    

          if( show_covadem_data & !viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
            viewerPotree.scene.pointclouds[i].visible = false;
          }     
          
          if( !show_covadem_data & !viewerPotree.scene.pointclouds[i].type.includes('cvd') ){
            viewerPotree.scene.pointclouds[i].visible = true;
          }                       
       
        } else {                
          viewerPotree.scene.pointclouds[i].visible = false;
        }
      } 
    } 
    
    var getAllPotreeDates = function()
     {
      var dateArray = new Array();
      for (let i = 0; i < db_potree_folders.length; i++) {
        if( db_potree_folders[i].date_measurement != null )
        {
          dateArray.push( (new Date( db_potree_folders[i].date_measurement)).getTime() )
        }
      }
      return dateArray
     }

     Date.prototype.addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
     }

  function getDates(startDate, stopDate) {
      var dateArray = new Array();
      var currentDate = startDate;
      while (currentDate <= stopDate) {
          dateArray.push(new Date (currentDate));
          currentDate = currentDate.addDays(1);
      }
      return dateArray;
  }     

  timeSlider = new TimeSlider({
        container: 'slider',
        fullTimeExtent: {
          start: new Date(2021, 9, 1),
          end: new Date(),
        },
        timeExtent: {
          start: new Date(new Date().setMonth(new Date().getMonth() - 6)),
          end: new Date(),
        },
        disabled: false,
        actions: [
          // {
          //   id: 'quake',
          //   icon: 'exclamation-mark-triangle',
          //   title: 'Jump to Earthquake',
          // },
        ],     
      })

      timeSlider.tickConfigs = [
      {
        mode: "position",
      values: getDates(timeSlider.fullTimeExtent.start, new Date()).map((date) => date.getTime()),
      labelsVisible: true,
        labelFormatFunction: (value) => {
          const date = new Date(value);
          if ( date.getUTCDate() === 1)
          {
            const month = date.toLocaleString('default', { month: 'long' });
            var result  = month
            return result
          }
          
          else{ return null}
        },
        tickCreatedFunction: (value, tickElement, labelElement) => {
          tickElement.classList.add("custom-ticks-months");
          labelElement.classList.add("custom-labels-months");
        }
      } 
      , 
      {
        mode: "position",
        values: [
          new Date(2020, 0, 1), new Date(2021, 0, 1), new Date(2022, 0, 1), new Date(2023, 0, 1), new Date(2024, 0, 1), new Date(2025, 0, 1), new Date(2026, 0, 1)
        ].map((date) => date.getTime()),
        labelsVisible: true,
        labelFormatFunction: (value) => {
          const date = new Date(value);
          var result  = `${date.getUTCFullYear()+1}`
          return result;
        },
        tickCreatedFunction: (value, tickElement, labelElement) => {
          tickElement.classList.add("custom-ticks-years");
          labelElement.classList.add("custom-labels-years");
        }
      }  
      , 
      {
        mode: "position",
        values: getAllPotreeDates() ,
        labelsVisible: true,
        labelFormatFunction: (value) => {
          var result  = ''
          return result;
        },
        tickCreatedFunction: (value, tickElement, labelElement) => {
          tickElement.classList.add("custom-ticks-metingen");
          labelElement.classList.add("custom-labels-metingen");
        }
      }        
    ];  
    
    // labelFormatFunction: (value, type, element, layout) => {
    //   if (!timeSlider.fullTimeExtent) {
    //     element.setAttribute(
    //       "style",
    //       "font-family: 'Orbitron', sans-serif; font-size: 11px; color: black;"
    //     );
    //     element.innerText = "loading..."
    //     return;
  
    //   const normal = new Intl.DateTimeFormat("en-us");
    //   switch (type) {
    //     case "min":
    //     case "max":
    //       element.setAttribute(
    //         "style",
    //         "font-family: 'Orbitron', sans-serif; font-size: 16px; color: magenta;"
    //       );
    //       element.innerText = normal.format(value);
    //       break;
    //     case "extent":
    //       const start = timeSlider.fullTimeExtent.start;
    //       const days = (value[0].getTime() - start.getTime()) / 1000 / 3600 / 24;
    //       element.setAttribute(
    //         "style",
    //         "font-family: 'Orbitron', sans-serif; font-size: 18px; color: red;"
    //       );
    //       element.innerText = `Day ${days}`;
    //       break;
    //   }
    // }    
  
      // timeSlider.layout = "vertical"
      timeSlider.stops = {
        interval: {
          value: 1,
          unit: "days"
        },
      };

      //console.log('timeSlider:  ', timeSlider)
    
      timeSlider.watch('timeExtent', (timeExtent) => {
        set_visibility_pointclouds(timeExtent.start, timeExtent.end);
      })

      // ---------------------------------------------------------------------------------------------------------- //
      // END of the Slider Code                                                                                     //
      // ---------------------------------------------------------------------------------------------------------- //      




      // ---------------------------------------------------------------------------------------------------------- //
      // Start of the eternal loop                                                                                  //
      // ---------------------------------------------------------------------------------------------------------- //      
      // Here we are keeping the Cesium Map and the Potree Viewer in sync - such that they both have the same       //
      // camera orientation.                                                                                        //
      // ---------------------------------------------------------------------------------------------------------- //      
      function loop(timestamp) {
        requestAnimationFrame(loop)

        viewerPotree.update(viewerPotree.clock.getDelta(), timestamp)
        viewerPotree.render()

        // Update the timerslider ticks (best to do this in viewer.js?)
        timeSlider.tickConfigs[2].values = Array();

        for (let fdate of viewerPotree.dates_in_frustrum) {
          if( fdate instanceof Date) {
            timeSlider.tickConfigs[2].values.push( fdate.getTime() );
          }
        }

        if (window.toMap !== undefined) {
          {
            let camera  = viewerPotree.scene.getActiveCamera()
            let pPos    = new THREE.Vector3(0, 0, 0).applyMatrix4(camera.matrixWorld)
            let pRight  = new THREE.Vector3(600, 0, 0).applyMatrix4(camera.matrixWorld)
            let pUp     = new THREE.Vector3(0, 600, 0).applyMatrix4(camera.matrixWorld)
            let pTarget = viewerPotree.scene.view.getPivot()

            let toCes = (pos) => {
              let xy = [pos.x, pos.y]
              let height = pos.z
              let deg = window.toMap.forward(xy)
              let cPos = Cesium.Cartesian3.fromDegrees(...deg, height)

              return cPos
            }

            let cPos      = toCes(pPos)
            let cUpTarget = toCes(pUp)
            let cTarget   = toCes(pTarget)

            let cDir = Cesium.Cartesian3.subtract(cTarget, cPos, new Cesium.Cartesian3())
            let cUp  = Cesium.Cartesian3.subtract(cUpTarget, cPos, new Cesium.Cartesian3())

            cDir = Cesium.Cartesian3.normalize(cDir, new Cesium.Cartesian3())
            cUp  = Cesium.Cartesian3.normalize(cUp, new Cesium.Cartesian3())

            viewerCesium.camera.setView({
              destination: cPos,
              orientation: {
                direction: cDir,
                up: cUp,
              },
            })
          }

          let aspect = viewerPotree.scene.getActiveCamera().aspect
          if (aspect < 1) {
            let fovy = Math.PI * (viewerPotree.scene.getActiveCamera().fov / 180)
            viewerCesium.camera.frustum.fov = fovy
          } else {
            let fovy = Math.PI * (viewerPotree.scene.getActiveCamera().fov / 180)
            let fovx = Math.atan(Math.tan(0.5 * fovy) * aspect) * 2
            viewerCesium.camera.frustum.fov = fovx
          }
        }
        viewerCesium.render()
      }

      // First call of the eternal loop
      requestAnimationFrame(loop)
    } // endof componentDidMount


    componentDidUpdate () {
        console.log('componentDidUpdate')
        console.log(this.state.potree_file_list)
    }
}