import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PlaygroundPage} from './PlaygroundPage'

const playgroundPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Playground</PageTitle>
      <PlaygroundPage />
    </>
  )
}

export default playgroundPageWrapper
