/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";

import { KTSVG } from '../../../_metronic/helpers'
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../../_metronic/layout/core'
import { getPostRequestHeader, useAuth } from '../../modules/auth'
import './OverViewPage.css'

const API_URL = process.env.REACT_APP_API_URL
const token: string | null = localStorage.getItem('access_token') 
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

interface dbEntry {
  // export interface dbEntry {
  id: number | null,
  zip_filename: string | null,
  potree_path: string | null,
  upload_date_start: string | null,
  upload_date_end: string | null,
  date_measurement: string | null,
  requires_processing: boolean | null,
  is_brv_added: boolean | null,
  is_potree_finished: boolean | null,
  is_uploaded: boolean | null,
  is_downloaded: boolean | null,
  date_downloaded: string | null,
  progress: number | null,
  date_converted: string | null,
  filepath_potree: string | null,
  remove_file: false | null,
  type: string | null,
  status_message: string | null,
  logfile: string | null,
}


const OverviewPage: React.FC = () => {

  const {currentUser, logout} = useAuth()
  let username = ''
  if (currentUser?.username === undefined){
    username = ''
  } else {
    username = currentUser?.username
  }
  

  const [init, setInit] = useState(true);
  const [type, setType] = useState("mtb");
  const [filterdfilelist, setFilteredFileList] = useState<dbEntry[]>();

  console.log("Render Started. Init = " + init)

  async function load_db_entries( type : any) {
    console.log('Reloading potreefolders')
    await axios.get(`${API_URL}/PotreeFolders`).then((res) => {
      // setFiles(res.data)
      var newFilelist: dbEntry[] = res.data
      var result = newFilelist.filter(item => {
        return item.type?.includes(type)
      });
      setFilteredFileList(result)
      console.log(result)
    })
  }
 
  function handleChange(event: any) {
    setType(event.target.value);
    load_db_entries(event.target.value)
  };


  // Removes an entry from the PotreeFolders database
  async function removeItem(id: any) {
    console.log("remove item clicked: ", id)
    console.log(id)
    // axios.get(`${API_URL}/PotreeFolders`).then((res) => {

    await axios.delete(`${API_URL}/PotreeFolders/${id}`) 
    // .then(res => this.refreshList() );
    load_db_entries( type )    
  }






  // This code should run only once, to initialize the states that need initialization.
  if( init ) 
  {
    load_db_entries( type )
    setInit(false)
  }

  console.log(filterdfilelist)
  console.log( type )

  return (
    <>
      <div className="container d-flex flex-column flex-lg-row" >
      <br></br>
        <div className="card card-docs mb-2 card mb-2 card-body fs-6 py-15 px-10 py-lg-15 px-lg-15" style = {{marginTop: "20px"}}>
          <p> Select the type of data to show below: </p>
          <select className = "form-select" value={type} onChange={handleChange}>
            <option value="mtb">Multibeam Scan</option>
            <option value="os">Oever scan</option>
            <option value="bv">Baggervlakken</option>
          </select>

      <br></br>
          {/* <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Member Statistics</span>
              <span className="text-muted mt-1 fw-semibold fs-7">Over 500 new members</span>
            </h3>
          </div>           */}

          <div className="table-responsive"  >
            <table className="table table-hover table-rounded table-striped border gy-1 gs-2">
              <thead>
                <tr className="fw-semibold fs-6 text-gray-800 border-bottom-2 border-gray-200" >
                  <th>Upload File Name</th>
                  {/* <th>Upload Date</th> */}
                  <th>Measurement Date</th>
                  <th>Processing Progress [%]</th>
                  {username === 'admin' && <th>Remove</th>}
                </tr>
              </thead>

              <tbody >
                {filterdfilelist?.map((item, i) => (
                  <tr className="even" key={i}>
                    <td>{item['zip_filename']}</td>
                    {/* <td>{item['upload_date_end']}</td> */}
                    <td>{item['date_measurement']}</td>
                    <td>{item['progress']}</td>
                    {username === 'admin' && <td><a onClick={() => removeItem(item['id'])} className="bi bi-trash3-fill fs-1x"></a></td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </>
  )


}

export { OverviewPage }

