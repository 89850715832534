import {AuthModel, UserModel} from './_models'
import jwtDecode from 'jwt-decode'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_STORAGE_ACCESS_TOKEN = 'access_token'

const getAuthJWT = (): AuthModel | undefined => {

  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_STORAGE_ACCESS_TOKEN)  
  if (!lsValue) {
    return
  }


  try {
    var auth: AuthModel = jwtDecode(lsValue) 
    auth.access = lsValue
    // const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

// Save the JWT access key to local storage
const setAuthJWT = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    // localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, auth.access)
    localStorage.setItem(AUTH_STORAGE_ACCESS_TOKEN, auth.access)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

// const removeAuth = () => {
//   if (!localStorage) {
//     return
//   }

//   try {
//     localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
//     localStorage.removeItem(AUTH_STORAGE_ACCESS_TOKEN)
//   } catch (error) {
//     console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
//   }
// }

const removeAuthJWT = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_STORAGE_ACCESS_TOKEN)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}


export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  // axios.interceptors.request.use(
  //   (config: {headers: {Authorization: string}}) => {
  //     const access_token: string | null = localStorage.getItem(AUTH_STORAGE_ACCESS_TOKEN)
  //     console.log(access_token)
  //     if (!access_token) {
  //       config.headers.Authorization = `Bearer ${access_token}`
  //     }

  //     return config
  //   },
  //   (err: any) => Promise.reject(err)
  // )
} 

async function getPostRequestHeader() {
  // refresh token every time new request is made
  console.log('IMPORTANT: ', localStorage.getItem(AUTH_STORAGE_ACCESS_TOKEN))
  if (localStorage.getItem(AUTH_STORAGE_ACCESS_TOKEN) != null){
      console.log('there is a access token in local storage')
      // better to have the actuall credentials of the current user
      // const refreshToken = await getRefreshToken('p.vanklaveren@dotxcontrol.com', 'Hello122;')
      // const AccessToken = await refreshAccessToken(refreshToken)
      // await updateData(JSON.stringify(AccessToken))
      let AccessToken = localStorage.getItem(AUTH_STORAGE_ACCESS_TOKEN)
      const headers = {
          'accept': 'application/json',
          'Authorization': 'Bearer ' + AccessToken,
      } 
      console.log(headers)
      return headers
  }
}

export {setAuthJWT, getAuthJWT, removeAuthJWT, getPostRequestHeader,  AUTH_STORAGE_ACCESS_TOKEN}
