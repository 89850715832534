/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";

import { KTSVG } from '../../../_metronic/helpers'
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../../_metronic/layout/core'
import { getPostRequestHeader } from '../../modules/auth'

import PointcloudNavigator from '../../modules/potreeReact/potreeViewer.js'


const API_URL = process.env.REACT_APP_API_URL
const token: string | null = localStorage.getItem('access_token') 
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
 
const PotreePage: React.FC = () => {
 
  return (
    <>
      <PointcloudNavigator />
    </>
  )


}

export { PotreePage }

