// ./src/azure-storage-blob.ts

// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import {AnonymousCredential, ShareServiceClient, newPipeline} from '@azure/storage-file-share'
import {AbortController} from '@azure/abort-controller'
import * as fs from 'fs'
import {TransferProgressEvent} from '@azure/core-http'
import {doc} from 'prettier'
import axios from 'axios'

const containerName = `uploads-frontend`
// const sasToken = process.env.REACT_APP_STORAGESASTOKEN;
const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME
const API_URL = process.env.REACT_APP_API_URL

const pipeline = newPipeline(new AnonymousCredential(), {
  // httpClient: MyHTTPClient, // A customized HTTP client implementing IHttpClient interface
  retryOptions: {maxTries: 4}, // Retry options
  userAgentOptions: {userAgentPrefix: 'AdvancedSample V1.0.0'}, // Customized user-agent string
  keepAliveOptions: {
    // Keep alive is enabled by default, disable keep alive by setting false
    enable: false,
  },
})

async function getSasToken() {
  const token: string | null = localStorage.getItem('access_token')
  axios.defaults.headers.common = {Authorization: `Bearer ${token}`}

  var result = await axios.get(`${API_URL}/getSasToken`)
  return result.data
}

const uploadFileToFileShare = async (file: File | null, id: number): Promise<string> => {
  if (!file) return ''

  var sasToken = await getSasToken()

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const serviceClient = new ShareServiceClient(
    `https://${storageAccountName}.file.core.windows.net?${sasToken}`,
    pipeline
  )

  // get Container - full public read access
  // const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  // await containerClient.createIfNotExists({
  //   access: 'container',
  // });
  // Create a share
  const shareName = `strukton-data`
  // const shareName = `newshare${new Date().getTime()}`;
  const shareClient = serviceClient.getShareClient(shareName)
  // await shareClient.create();
  // console.log(`Created share ${shareName} successfully`);

  // Create a directory
  // const directoryName = `newdirectory${new Date().getTime()}`;
  const directoryName = `.`
  const directoryClient = shareClient.getDirectoryClient(directoryName)
  // await directoryClient.create();
  console.log(`Created directory ${directoryName} successfully`)

  // Upload local file to Azure file parallelly
  //  const fileName = new Date().getTime() + "_" + file.name;
  const fileName = id + '_' + file.name
  const fileClient = directoryClient.getFileClient(fileName)

  function round(value: number, precision: number) {
    var multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  }

  function show_progress(file: File, ev: TransferProgressEvent) {
    var progress = (ev.loadedBytes / file.size) * 100.0
    progress = round(progress, 1)
    const element: HTMLElement = document.getElementById('file_progress') as HTMLElement
    element.innerHTML = 'Progress: ' + progress + ' %'
  }

  // Parallel uploading with ShareFileClient.uploadFile() in Node.js runtime
  // ShareFileClient.uploadFile() is only available in Node.js
  await fileClient.uploadData(file, {
    rangeSize: (4 * 1024 * 1024) / 4, // 4MB range size
    concurrency: 1, // 20 concurrency
    // onProgress: (ev) => console.log( (file.size / ev.loadedBytes)*100.0 + "%%")
    onProgress: (ev) => show_progress(file, ev),
  })

  console.log('uploadFile succeeded')

  // get list of blobs in container
  return fileName
}

export {uploadFileToFileShare, getSasToken}
