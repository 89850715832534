/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_metronic/layout/core'
// import Path from 'path';
import {uploadFileToFileShare} from './azure-storage-blob';
import axios from 'axios';
import { useAuth } from '../../modules/auth';

const API_URL = process.env.REACT_APP_API_URL
const token: string | null = localStorage.getItem('access_token') 
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}


type dbEntry = {
  zip_filename        : string| null,
  potree_path         : string| null,
  upload_date_start   : string | null,
  upload_date_end     : string | null, 
  date_measurement    : string | null,
  requires_processing : boolean| null, 
  is_brv_added        : boolean| null, 
  is_potree_finished  : boolean| null, 
  is_uploaded         : boolean| null, 
  is_downloaded       : boolean| null, 
  date_downloaded     : string| null,
  progress            : number| null,
  date_converted      : string| null, 
  filepath_potree     : string | null, 
  remove_file         : false | null  ,
  type                : string| null  ,
  status_message      : string | null, 
  logfile             : string| null ,
}| null;


const FileUploadPage: React.FC = () => {

  const {currentUser, logout} = useAuth()
  let username = ''
  if (currentUser?.username === undefined){
    username = ''
  } else {
    username = currentUser?.username
  }
 
  // all blobs in container
  const [blobList, setBlobList] = useState<string[]>([]);

  // current file to upload into container
  const [fileSelected, setFileSelected] = useState(null);

  // UI/form management
  const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  // JK: Added the other input field(s)
  const [inputType, setInputType] = useState("mtb");
  const [inputDate, setInputDate] = useState();
  


  const onFileChange = (event: any) => {
    // capture file into state
    setFileSelected(event.target.files[0]);
  };

  const onMeasDateChange = (event: any) => {
    console.log('onMeasDateChange: ', event.target.value)
    setInputDate(event.target.value)
  }

  const onClickStartUpload = async () => {
    // prepare UI
    setUploading(true);

    console.log("Start uploading " + fileSelected)

    var my_zip_filename = File

    if (fileSelected!=null && inputDate!=null)
    {
      my_zip_filename = fileSelected
    

      // Add the upload to the SQL database. 
      var my_upload: dbEntry = {
        zip_filename        : my_zip_filename.name,
        potree_path         : null,
        upload_date_start   : new Date().toJSON(),
        upload_date_end     : null, 
        date_measurement    : inputDate,
        requires_processing : true, 
        is_brv_added        : false, 
        is_potree_finished  : false, 
        is_uploaded         : false, 
        is_downloaded       : false, 
        date_downloaded     : null,
        progress            : 0,
        date_converted      : null, 
        filepath_potree     : null, 
        remove_file         : false,
        type                : inputType,
        status_message      : null, 
        logfile             : null,
      };

      // Add the new database entry
      var res = await axios.post(`${API_URL}/PotreeFolders`, my_upload)
      const id = res.data.id;

      // Upload the selected file(s) to Azure.
      const uploaded_file: string = await uploadFileToFileShare(fileSelected, id);


      console.log("End of uploading. uploaded_file: ", uploaded_file)

      // Save the time the upload was finished. That also means that the upload was succesfull.
      // This way we can parse the database for unsuccesfull uploads, and remove them once in a while.
      my_upload.upload_date_end = new Date().toJSON();
      my_upload.zip_filename    = uploaded_file;

      res = await axios.put(`${API_URL}/PotreeFolders/${id}`, my_upload)

      // prepare UI for results
      // setBlobList(blobsInContainer);
    }
      // reset state/form
      setFileSelected(null);
      setUploading(false);
      setInputKey(Math.random().toString(36));
    
  };
 


  const DisplayStartUploadButton = () => (
    <button type="submit" className="btn btn-flex btn-primary px-6 indication" onClick={onClickStartUpload} data-kt-indicator="on">
    <span className="bi bi-cloud-upload fs-2x"></span>
    <span className="d-flex flex-column align-items-start ms-2">
    <span className="fs-3 fw-bolder">Upload Data</span>
      <span id = "file_progress" className="fs-7"></span>
      {/* <meter min="28900" max="31100" value="30000"></meter> */}
    </span>
  </button>
  );

  const DisplayStopUploadButton = () => (
    <button type="submit" className="btn btn-flex btn-primary px-6 indication disabled" onClick={onClickStartUpload} data-kt-indicator="on">
    <span className="bi bi-cloud-upload fs-2x"></span>
    <span className="d-flex flex-column align-items-start ms-2">
     <span className="fs-3 fw-bolder">Uploading...</span>
      <span id = "file_progress" className="fs-7"></span>
      {/* <meter min="28900" max="31100" value="30000"></meter> */}
    </span>
  </button>
  );  
  

  // display form
  const DisplayForm = () => (
    <div className="rounded border p-10">
      <div className="mb-10">
      <label className="form-label">Select the data type</label>
        <select className="form-select" aria-label="Select example" onChange={(e) => setInputType(e.target.value)}>
          <option value="mtb">Bodem Multibeam Scan</option>
          <option value="os">Oever LIDAR Scan</option>
        </select>
        <br></br>

        <label className="form-label">Datum van opname</label>
        <input type="date" className="form-control" id="start" name="trip-start"  
          onChange={onMeasDateChange} //(e) => setInputDate(e.target.value)
          value={inputDate}
          min="2018-01-01" max="2030-12-31">
        </input>

        <br></br>
        <input type="file" className="form-control" onChange={onFileChange} key={inputKey || ''} />
        <br></br>
        { username === 'admin' && !uploading && DisplayStartUploadButton() }
        { username === 'admin' && uploading && DisplayStopUploadButton() }
        <br></br>

      </div>
    </div>
  )

  return (
    <>
      <div className="container d-flex flex-column flex-lg-row">
        <div className="card mb-2 card-body fs-6 py-15 px-10 py-lg-15 px-lg-15" style = {{marginTop: "20px"}}>
          <h1 className="anchor fw-bolder mb-5">Add a new measurements</h1>



          <div className = "py-5">This page allows uploading new multibeam and lidar scans. The files should be uploaded as a <strong>*.zip</strong> file, and when extracted, the root directory should directly contain all <strong>.xyz</strong> files.</div>

          {/* {1 && !uploading && DisplayForm()} */}
          {1 && DisplayForm()}
          {/* {1 && uploading && 1} */}
          {/* <hr /> */}
        </div>    </div>
    </>
  )
}

export {FileUploadPage}


